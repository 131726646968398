body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Style for the scrollbar track */
::-webkit-scrollbar {
  width: 22px; /* Width of the scrollbar */
}

/* Style for the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the thumb */
  border-radius: 6px; /* Rounded corners for the thumb */
}

/* Style for the scrollbar track on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the thumb on hover */
}

/* Style for the scrollbar track when dragging the thumb */
::-webkit-scrollbar-thumb:active {
  background-color: #333; /* Color of the thumb when active */
}